<template>
  <div class="loading-layout">
    <component :is='activeComponent' :description="getDescription" @reaty="reatyClickHandle"></component>
    <div v-show="status == 'empty' && $slots.empty">
      <slot name="empty"></slot>
    </div>
    <div v-show="status == 'success'">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingLayout',
  loading: 'loading',
  empty: 'empty',
  error: 'error',
  netError:'net_error',
  success: 'success',
  props: {
    // 出错时的提示文章
    errorText: {
      type: String,
      default: '出错啦~请稍后重试'
    },
    emptyText: {
      type: String,
      default: '抱歉，暂无数据'
    },
    netErrorText: {
      type: String,
      default: '无网络连接，请检查您的网络···'
    },

  },
  components:{
    'loading': () => import('./loading'),
    'empty': () => import('./empty'),
    'error': ()=> import('./load-error'),
    'net-error': ()=> import('./network-error')
  },
  data() {
    return {
      status: '',
      activeComponent: null
    }
  },
  computed: {
    getDescription() {
      let desc = '';
      switch(this.status) {
        case 'error':
          desc = this.errorText;
          break;
        case 'empty':
          desc = this.emptyText;
          break;
        case 'net-error':
          desc = this.netErrorText;
          break;
        default:
          break;
      }
      return desc;
    }
  },
  methods: {
    setStatus(status) {
      this.status = status;
      if (status == 'empty' && this.$slots.empty) {
        this.activeComponent = null;
      } else {
        this.activeComponent = status == 'success' ? null : status;
      }
      console.log("Status:", this.status,this.$slots)
    },
    reloadButtonClickHandle() {
      this.$emit('reload');
    },
    reatyClickHandle() {
      this.$emit('reaty')
    }
  },
}
</script>

<style lang="scss" scoped>
  .loading-layout /deep/{

    .van-empty__image {
      width: 120px;
      height: 120px;
      img {
        object-fit: contain;
      }
    }
  }
</style>