import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './icons' // icon

import * as filters from './filters' // global filters
import * as config from '../config'

import { 
  Toast,
  Notify,
  Loading,
  NavBar,
  Col, Row,
  Icon,
  Grid, 
  GridItem,
  Form,
  Field,
  Switch,
  Button,
  CellGroup,
  Cell,
  Image as VanImage,
  Lazyload,
  Empty,
  ActionSheet,
  List,
  Picker,
  Popup,
  Tag,
  Dialog,
  DatetimePicker,
  Checkbox
} from 'vant';

import PageView from './components/PageView/index';
import LoadingLayout from './components/LoadingLayout/index';
import DividerBox from './components/DividerBox/index';
import Card from './components/Card';

Toast.setDefaultOptions({ duration: 3000 });

Vue.use(Loading);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Notify);
Vue.use(NavBar);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Switch);
Vue.use(Button);
Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(VanImage);
Vue.use(Lazyload);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Empty);
Vue.use(ActionSheet);
Vue.use(List);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Tag);
Vue.use(DatetimePicker);
Vue.use(Checkbox);


Vue.use(PageView)
Vue.use(LoadingLayout)
Vue.use(DividerBox)
Vue.use(Card)

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.prototype.$conf = config;
Vue.config.productionTip = false

Vue.config.errorHandler = function(err, vm, info) {
  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
  console.log(err, vm, info);
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
