import { getOpenId, getToken, setToken, removeToken } from '@/utils/auth'
import {  loginWithOpenId } from '../../api/login'
import { hlwZcyh_getZcyhByOpenid,hlwYxyh_getYxyhByOpenid  } from '../../api/grzx';


import { Notify, Toast } from 'vant'

const user = {
  state: {
    openId: getOpenId(),
    token: getToken() ,
    dlzh: undefined,
    czyh: undefined,
    jgbm: undefined,
    dybm: undefined,
    email: undefined,
    htglbz: undefined,
    sfzh: undefined,
    yhid: undefined,
    yhmc: undefined,
    yhsj: undefined,
    yhbhList: []
  },
  mutations: {
    SET_OPEN_ID: (state, value) => {
      state.openId = value;
    },
    SET_TOKEN: (state, value) => {
      state.token = value;
    },
    SET_PHONE: (state, value) => {
      state.phone = value;
    },
    SET_USERINFO: (state, userinfo) => {
      state.dlzh = userinfo.dlzh; // 登录账号(对应openId)
      state.czyh = userinfo.czry;
      state.jgbm = userinfo.jgbm;
      state.dybm = userinfo.dybm;
      state.email = userinfo.email;
      state.htglbz = userinfo.htglbz;
      state.sfzh = userinfo.sfzh;
      state.yhid = userinfo.yhid;
      state.yhmc = userinfo.yhmc;
      state.yhsj = userinfo.yhsj;
    },
    SET_YHBH_LIST: (state, list) => {
      state.yhbhList = list;
    }
  },
  actions: {
    // 通过 openId 登录
    loginWithOpenId({ commit }) {
      const openId = getOpenId();
      commit('SET_OPEN_ID', openId);
      return new Promise((resolve, reject) => {
        loginWithOpenId(openId).then(resp => {
          if (resp.status == 200) {
            const {token, phone} = resp.data;
            commit('SET_TOKEN', token);
            setToken(token);
            resolve(token);
          } else {
            reject(new Error(resp.message));
          }
        }).catch(err => {
          reject(err);
        });
      })
    },

    // 拉取用户信息
    getUserInfo({commit, state}) {
      return new Promise((resolve, reject) => {
        hlwZcyh_getZcyhByOpenid(state.openId).then(response => {
          console.log(response)
          if (response.code == 200 ) {
            if (response.content) {
              let userinfo = response.content;
              commit('SET_USERINFO', userinfo);
              resolve(userinfo);
            } else {
              throw new Error('用户未成功注册！');
            }
          } else {
            throw new Error('用户信息获取失败:',response.message);
          }
        }).catch(err => {
          Notify({message: err.message, type: 'danger'});
          reject(err);
        });
      });
    },
    // 拉取用户编号列表
    getYhbhList({commit, state}) {
      return new Promise((resolve, reject) => {
        hlwYxyh_getYxyhByOpenid(state.openId).then(res => {
          const list = res.content;
          commit('SET_YHBH_LIST', list);
          resolve(list);
        }).catch(err => {
          
          Notify({message: '用户编号列表获取失败！', type: 'danger'});
          reject(err);
        });
      })
    },
    resetToken({commit}) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    }
  },
};

export default user;