<template>
  <div class="card" :class="cardClass">
    <div class="card-header" v-if="$slots.header || title">
      <slot name="header" v-if="$slots.header"></slot>
      <span class="card-header--title" v-else>{{title}}</span>
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
    <div class="card-footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      default: ''
    },
    shadow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      
    }
  },
  computed: {
    cardClass() {
      let arr = [];
      if (this.shadow) {
        arr.push('card-with-shadow');
      }

      return arr.join(' ')
    }
  },
}
</script>

<style lang="scss" scoped>
  .card {
    background-color: #fff;
    border-radius: 4px;
  }
  .card-with-shadow {
    box-shadow: 0 2px 15px -5px rgba(0,0,0,.26);
  }
  .card-header {
    padding: 10px 10px 0 10px;
    font-size: 14px;
  }
  .card-body {
    padding: 10px;
  }

  .card-footer {
    padding: 0 10px 10px 10px;
  }
</style>