import Currency from '../utils/currency';

export function yhmcFilter(val) {
  if (val === undefined || val === null) return ''; 
  let str = val.toString();
  let result = '';
  if (str.length > 0) {
    result = str.substr(0, 1) + '*' + str.substr(-1, 1)
  }
  return result;
};

export function yhbhFilter(val) {
  if (val === undefined || val === null) return '';
  let str = val.toString();
  let result = '';
  if (str.length > 0) {
    result = str.substr(0, 4) + '****' + str.substr(-1, 4)
  }
  return result;
};

export function zfdmFilter(value, zfdmList) {
  let result = '';
  for (let [i,v] of zfdmList.entries()) {
    if (v.dmbh == value) {
      result = v.dmnr;
      break;
    }
  }
  return result;
}

// 格式化文件大小
export function formatFilesize(fileSize) {
  if (fileSize < 1024) {
    return fileSize + 'B';
  } else if (fileSize < (1024 * 1024)) {
    var temp = fileSize / 1024;
    temp = temp.toFixed(2);
    return temp + 'KB';
  } else if (fileSize < (1024 * 1024 * 1024)) {
    var temp = fileSize / (1024 * 1024);
    temp = temp.toFixed(2);
    return temp + 'MB';
  } else {
    var temp = fileSize / (1024 * 1024 * 1024);
    temp = temp.toFixed(2);
    return temp + 'GB';
  }
}

export function currency(value, precision=2) {
  let CNY = Currency(value, {
    symbol: "¥", precision: precision
  });
  return CNY.format();
}

export function currencyDollars(value) {
  return Currency(value).dollars();
}


export function currencyCents(value) {
  return Currency(value).cents();
}