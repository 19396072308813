import store from '@/store';
import { Dialog,Toast} from 'vant';
import * as Utils from './index';
import { getOpenId } from './auth';

export default function showAuthDialog(next) {
  // 没有登录, 提示需要登录后才能继续访问
  Dialog.confirm({
    title: '未登录',
    message: '此功能需要登录后才能使用',
    confirmButtonText: '登录'
  }).then(() => {
    if (getOpenId()) {
      let loadingInstance = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '登录中',
      });
      store.dispatch('loginWithOpenId').then(res => {
        loadingInstance.clear();
        Toast.success('登录成功！');
        next && next();
      }).catch(err => {
        loadingInstance.clear();
        Toast.fail('登录失败：'+"【"+err+"】");
      });
    } else { // 尝试获取 code, 后续通过 Home 页面接收 code, 然后通过code 换取 openId,并通过 openId 登录 
      const local = window.location.href;
      let uri = Utils.getWxOauthUrl(local);
      window.location.href = uri;
    }
  }).catch(() => {
    next && next('/');
  });
}