module.exports = {
  APP_NAME: process.env.VUE_APP_NAME || '',
  VERSION: process.env.VUE_APP_VERSION || '0.0.1', // 10.0.1, man
  VERSION_VALUE: process.env.VUE_APP_VERSION_VALUE || 1,
  WX_APP_ID: process.env.VUE_APP_WX_APP_ID,
  BASE_URL: process.env.VUE_APP_BASE_URL || '/api/',
  DEFAULT_CZYH: process.env.DEFAULT_CZYH || '9210', // 
  DEFAULT_JGBM: process.env.DEFAULT_JGBM || '1R6',
  DEFAULT_DYBM: process.env.DEFAULT_DYBM || '1',
  DEFAULT_PAY_CZYH: process.env.DEFAULT_PAY_CZYH || '9110', // 支付业务操作员
};
